const client = `https://staging-bookings.cinesend.com`
const accountsClient = `https://staging-accounts.cinesend.com`
const api = `https://staging-api.cinesend.com/api`
const stripeKey = `pk_test_gXaQ6qFcGgqjwEHA1wjYKxcn`

export const GLOBALS = {
  PORT: 8008,
  BUILD_ENV: 'staging',
  URL: client,
  API_URL: api,
  ACCOUNT_REDIRECT_LOGIN_URL: `${accountsClient}/login`,
  STRIPE_KEY: stripeKey,
  SETTINGS_URL: `${accountsClient}/settings/profile`,
  PUSHER_KEY: '0edd289b7933fc036740',
  PUSHER_DEBUG: true
}
